import React from 'react';
import Countdown from './Countdown'; // Import Countdown.js component

// Sample motivational quote function
const getMotivationalQuote = () => {
    let x = [
        "Believe you can and you're halfway there.",
        "Success is stumbling from failure to failure with no loss of enthusiasm – or at least a good coffee.",
        "Believe in yourself, and if that’s too hard, at least believe in caffeine. Baby steps.",
        "Dream big, but remember, even the most epic novels started with a single typo.",
        "Don’t worry if you fall short of your goals. Just remember that most superheroes started with a lab accident or two.",
        "The best time to plant a tree was 20 years ago. The second best time is… fine, maybe tomorrow.",
        "Life is like a bicycle: to keep your balance, you must keep moving… unless there's ice cream. Then, stop and enjoy.",
        "You don’t have to see the whole staircase, just take the first step… unless it’s an escalator. In that case, relax and enjoy the ride.",
        "Be yourself; everyone else is taken, and frankly, they're also a lot of work.",
        "The elevator to success is out of order. You’ll have to take the stairs… one coffee-fueled step at a time.",
        "A successful project is 90% planning, 10% execution, and 100% explaining what just happened.",
        "Project management: the art of keeping people excited about work they wish they'd never agreed to.",
        "Plan as if everything will go right; prepare as if everything will go wrong.",
        "Managing a project is like juggling flaming torches… blindfolded… and the torches are emails.",
        "Deadlines are closer than they appear in the project plan.",
        "Success in IT is 90% showing up, 10% knowing which video call is currently live.",
        "If everything seems to be going well, you’ve probably missed an email.",
        "If opportunity doesn’t knock, build a rocket.chat channel and tag everyone.",
        "A project without clear requirements is just a meeting waiting to happen.",
        "In project management, every day is a team-building exercise you never signed up for.",
        "They say every journey begins with a single step. In project management, it’s a single meeting, and then 14 more.",
        "Building your app is like crafting a masterpiece—except with fewer paintbrushes and more API calls",
        "Imagine this app is a Dundie Award: it’s taken time, dedication, and just the right amount of awkward moments to make it special.",
    ]
    return x[parseInt(Math.random() * x.length)];
}

const Home = () => {
    return (
        <div className="home">
            <div className="section" style={{ backgroundImage: 'url(./images/xmas/' + parseInt(Math.random() * 12 + 1, 10) +  '.png)' }}>
                <h2>Days until Christmas</h2>
                <Countdown targetDate={getNextXmasDate()} />
            </div>

            <div className="section" style={{ backgroundImage: 'url(./images/summer/' + parseInt(Math.random() * 9 + 1, 10) +  '.png)' }}>
                <h2>Days until Summer</h2>
                <Countdown targetDate={getSummerCountdownDate()} />
            </div>

            <div className="section" style={{ backgroundImage: 'url(./images/ny/' + parseInt(Math.random() * 5 + 1, 10) +  '.png)' }}>
                <h2>Days until New Year</h2>
                <Countdown targetDate={new Date('2025-01-01')} />
            </div>

            <div className="section" style={{ backgroundImage: 'url(./images/motivation/' + parseInt(Math.random() * 5 + 1, 10) +  '.png)' }}>
                <h2>Motivational Quote</h2>
                <p>{getMotivationalQuote()}</p>
            </div>
        </div>
    );
};

const getNextXmasDate = () => {
    return new Date(`2024-12-25`);
}

// Helper function to calculate the summer countdown or remaining days
const getSummerCountdownDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const summerStart = new Date(`${year}-06-01`);
    const summerEnd = new Date(`${year}-09-01`);
    if (now < summerStart) {
        return summerStart;
    } else if (now > summerEnd) {
        return new Date(`${year + 1}-06-01`);
    } else {
        return summerEnd;
    }
};

export default Home;